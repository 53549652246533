import { createFeature, createReducer, on } from '@ngrx/store';
import { UploadState } from './models';
import { uploadActions } from './upload.actions';

export const uploadInitialState: UploadState = {
  fileName: null,
  resultText: null,
  errorCode: '',
};

export const uploadReducer = createReducer(
  uploadInitialState,

  on(uploadActions.uploadStarted, (state, { fileName }) => ({
    ...state,
    fileName,
  })),

  on(
    uploadActions.textUploadSuccess,
    uploadActions.getTranscriptionSuccess,
    (state, { resultText }) => ({
      ...state,
      resultText,
    }),
  ),

  on(
    uploadActions.reset,
    uploadActions.cancel,
    uploadActions.uploadFailure,
    uploadActions.textUploadFailure,
    uploadActions.startAgain,
    () => uploadInitialState,
  ),

  on(
    uploadActions.mediaUploadFailure,
    uploadActions.getTranscriptionFailure,
    (state, { errorCode }) => ({
      ...state,
      errorCode,
    }),
  ),
);

export const uploadFeature = createFeature({
  name: 'upload',
  reducer: uploadReducer,
});
