import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_PREFIX, RESOURCES } from 'rio-core';
import { Observable } from 'rxjs';
import { TranscriptionResult } from './models';

@Injectable()
export class UploadService {
  API_PREFIX = inject(API_PREFIX);
  http = inject(HttpClient);

  getTranscription(
    organizationId: string,
    taskId: string,
  ): Observable<TranscriptionResult> {
    const url = `${this.API_PREFIX}${RESOURCES.TRANSCRIBE.transcription(organizationId, taskId)}`;

    return this.http.get<TranscriptionResult>(url);
  }
}
