import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TranscriptionErrorCode } from './models';

export const uploadActions = createActionGroup({
  source: 'Upload',
  events: {
    uploadStarted: props<{ fileName: string }>(),
    uploadComplete: props<{
      fileName: string;
      jsonResult: string;
    }>(),
    uploadFailure: emptyProps(),

    mediaUploadComplete: props<{ jsonResult: string }>(),
    mediaUploadFailure: props<{ errorCode: TranscriptionErrorCode }>(),

    startPooling: props<{ taskId: string }>(),
    getTranscription: props<{ taskId: string }>(),
    getTranscriptionUnfinished: emptyProps(),
    getTranscriptionSuccess: props<{ resultText: string }>(),
    getTranscriptionFailure: props<{ errorCode: TranscriptionErrorCode }>(),

    cancel: emptyProps(),
    reset: emptyProps(),
    startAgain: emptyProps(),

    textUploadComplete: props<{ jsonResult: string }>(),
    textUploadSuccess: props<{ resultText: string }>(),
    textUploadFailure: emptyProps(),
  },
});
