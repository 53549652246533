import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { has } from 'lodash';
import { isValidJson, UploadError, UploadFile, userFeature } from 'rio-core';
import { toastActions } from 'rio-toasts';
import { catchError, interval, map, of, switchMap, takeUntil } from 'rxjs';
import { isTextFile } from '../accepted-files/accepted-files';
import { TranscribeFileResponse } from './models';
import { uploadActions } from './upload.actions';
import { UploadService } from './upload.service';

@Injectable()
export class UploadEffects {
  uploadComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.uploadComplete),
      map(({ fileName, jsonResult }) => {
        if (isTextFile(fileName)) {
          return uploadActions.textUploadComplete({ jsonResult });
        }

        return uploadActions.mediaUploadComplete({ jsonResult });
      }),
    ),
  );

  mediaUploadComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.mediaUploadComplete),
      map(({ jsonResult }) => {
        const isJson = isValidJson(jsonResult);

        if (!jsonResult || !isJson) {
          // FAILURE: Response is empty or is not valid json when BE returns error
          return uploadActions.mediaUploadFailure({ errorCode: 'UNKNOWN' });
        }

        const response: TranscribeFileResponse = JSON.parse(jsonResult);

        if (!response.task_id) {
          const errorCode = response.error_code || 'UNKNOWN';

          return uploadActions.mediaUploadFailure({ errorCode });
        }

        return uploadActions.startPooling({ taskId: response.task_id });
      }),
    ),
  );

  startPooling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.startPooling),
      switchMap(({ taskId }) =>
        interval(5000).pipe(
          map(() => uploadActions.getTranscription({ taskId })),
          takeUntil(
            this.actions$.pipe(
              ofType(
                uploadActions.getTranscriptionSuccess,
                uploadActions.getTranscriptionFailure,
                uploadActions.cancel,
                uploadActions.reset,
              ),
            ),
          ),
        ),
      ),
    ),
  );

  getTranscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.getTranscription),
      concatLatestFrom(() =>
        this.store.select(userFeature.selectCurrentOrganizationId),
      ),
      switchMap(([{ taskId }, organizationId]) =>
        this.uploadService.getTranscription(organizationId, taskId).pipe(
          map(result => {
            if (result.status === 'SUCCESS') {
              return uploadActions.getTranscriptionSuccess({
                resultText: result.result.text,
              });
            }

            return uploadActions.getTranscriptionUnfinished();
          }),
          catchError(error =>
            of(
              uploadActions.getTranscriptionFailure({
                errorCode: error?.error?.error_code,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  textUploadFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.textUploadFailure),
      map(() =>
        toastActions.showSingleToast({
          toastType: 'error',
          text: 'Errors.Transcription failed',
        }),
      ),
    ),
  );

  textFileUploaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.textUploadComplete),
      map(({ jsonResult }) => {
        const isJson = isValidJson(jsonResult);

        if (!jsonResult || !isJson) {
          // FAILURE: Response is empty or is not valid json when BE returns error
          return uploadActions.textUploadFailure();
        }

        const response: UploadFile | UploadError = JSON.parse(jsonResult);

        if (has(response as UploadError, 'error_code')) {
          // FAILURE: There is a code_error in response when user uploads a file with wrong format
          return uploadActions.textUploadFailure();
        }

        const resultText = (response as UploadFile).text;

        // SUCCESS: There is a response with text when everything is ok
        return uploadActions.textUploadSuccess({ resultText });
      }),
    ),
  );

  textFileUploadedWithFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.textUploadFailure),
      map(() => toastActions.showStackedToast({ text: 'Errors.Upload File' })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private uploadService: UploadService,
  ) {}
}
