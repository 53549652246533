const acceptedDocuments = '.txt, .docx, .pdf, .html,';
const acceptedAudio = '.aac, .flac, .m4a, .mp3, .ogg, .opus, .wav';
const acceptedVideo =
  '.asf, .avi, .flv, .m4v, .mkv, .mp4, .mpeg, .mpg, .ogv, .webm, .wmv, .mov, .ts';

export const maxTextFileSize = 20 * 1024 * 1024; // 20 MB
export const maxMediaFileSize = 200 * 1024 * 1024; // 200 MB

export const acceptedMediaFiles = `${acceptedAudio}, ${acceptedVideo}`;
export const acceptedFiles = `${acceptedDocuments}, ${acceptedMediaFiles}`;

export function isTextFile(fileName: string): boolean {
  const extension = fileName.split('.').pop();

  return acceptedDocuments.includes(extension.toLocaleLowerCase());
}

export function isAudioFile(fileName: string): boolean {
  const extension = fileName.split('.').pop();

  return acceptedAudio.includes(extension.toLocaleLowerCase());
}

export function isVideoFile(fileName: string): boolean {
  const extension = fileName.split('.').pop();

  return acceptedVideo.includes(extension.toLocaleLowerCase());
}
