import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { trackingActions, TrackingEvent } from 'rio-tracking';
import { filter, map } from 'rxjs/operators';
import { isAudioFile, isVideoFile } from '../../accepted-files/accepted-files';
import { uploadActions } from '../upload.actions';
import { uploadFeature } from '../upload.reducer';
import {
  AudioFileUploadedTrackingPayload,
  VideoFileUploadedTrackingPayload,
} from './models';

@Injectable()
export class UploadTrackingEffects {
  audioFileUploaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.mediaUploadComplete),
      concatLatestFrom(() => this.store.select(uploadFeature.selectFileName)),
      filter(([, fileName]) => isAudioFile(fileName)),
      map(
        ([, fileName]): AudioFileUploadedTrackingPayload => ({
          fileExtension: fileName?.split('.')?.pop(),
        }),
      ),
      map(payload =>
        trackingActions.track({
          trackingEvent: TrackingEvent.Transcriptions.AudioFileUploaded,
          payload,
        }),
      ),
    ),
  );

  videoFileUploaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.mediaUploadComplete),
      concatLatestFrom(() => this.store.select(uploadFeature.selectFileName)),
      filter(([, fileName]) => isVideoFile(fileName)),
      map(
        ([, fileName]): VideoFileUploadedTrackingPayload => ({
          fileExtension: fileName?.split('.')?.pop(),
        }),
      ),
      map(payload =>
        trackingActions.track({
          trackingEvent: TrackingEvent.Transcriptions.VideoFileUploaded,
          payload,
        }),
      ),
    ),
  );

  transcriptionCancelled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.cancel),
      map(() =>
        trackingActions.track({
          trackingEvent: TrackingEvent.Transcriptions.TranscriptionCancelled,
        }),
      ),
    ),
  );

  transcriptionSuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadActions.getTranscriptionSuccess),
      map(() =>
        trackingActions.track({
          trackingEvent: TrackingEvent.Transcriptions.TranscriptionSuccessful,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}
}
