import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UploadTrackingEffects } from './tracking/upload-tracking.effects';
import { UploadEffects } from './upload.effects';
import { uploadFeature } from './upload.reducer';
import { UploadService } from './upload.service';

@NgModule({
  imports: [
    StoreModule.forFeature(uploadFeature),
    EffectsModule.forFeature([UploadEffects, UploadTrackingEffects]),
  ],
  providers: [UploadService],
})
export class UploadStoreModule {}
